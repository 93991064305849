import React, { useEffect, useState } from "react";
import Index from "../../Index";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/slices/AdminSlice";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
// import { logout } from "../../../redux/slices/adminService";
export default function Sidebar(props) {
  const navigate = useNavigate();
  // open sidebar usign handalclick
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [screen, setScreen] = useState("");
  const handleClickAccount = () => {
    setOpen(!open);
  };
  const handleClick = () => {
    setOpen(false);
  };

  const handlelogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const { rolePermission } = useSelector((state) => state.admin);

  useEffect(() => {
    if (window.screen.width < 768) {
      setScreen("Mobile");
    } else {
      setScreen("Desktop");
    }
  }, [window.screen.availHeight]);

  return (
    <>
      <Index.Box
        className={`admin-sidebar-main ${
          (screen == "Mobile" ? !props.open : props.open) ? "active" : ""
        }`}
      >
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Box className="admin-sidebar-logo-main">
            <img
              src={Index.Png.socialMediaLogoWhite}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Button
              onClick={() => {
                props.setOpen(!props.open);
                document.body.classList[props.open ? "add" : "remove"](
                  "body-overflow"
                );
              }}
              className="sidebar-close-btn"
            >
              <img
                src={Index.Png.socialMediaLogoWhite}
                className="close-icon"
                alt="logo"
              />
            </Index.Button>
          </Index.Box>

          <Index.Box className="admin-sidebar-list-main">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list  cus-sidebar-list">
                <Index.Link
                  to="/dashboard"
                  // className="admin-sidebar-link active"
                  className={`admin-sidebar-link ${
                    location.pathname === "/dashboard" ? "active" : ""
                  }`}
                  onClick={handleClick}
                >
                  {/* <img
                    src={Index.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  /> */}
                  <Index.DashboardRoundedIcon className="admin-sidebar-icons" />
                  Dashboard
                </Index.Link>
              </Index.ListItem>
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "CryptoList_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/crypto-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/crypto-list"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      {/* <img
                        src={Index.Png.bitcoin}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      /> */}
                      <Index.CurrencyBitcoinIcon className="admin-sidebar-icons" />
                      Crypto List
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "CryptoList_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/music-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/music-list"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      {/* <img
                        src={Index.Png.bitcoin}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      /> */}
                      <Index.CurrencyBitcoinIcon className="admin-sidebar-icons" />
                      Music List
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "WithdrawalList_view"
                )) ||
              (rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "ConversionList_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/withdrawal-conversion-request-list"
                      className={`admin-sidebar-link ${
                        location.pathname ===
                        "/dashboard/withdrawal-conversion-request-list"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index.Png.currencyExchange}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      {rolePermission && rolePermission?.isAdmin === true ? (
                        <div>Withdrawal and Conversion Request List</div>
                      ) : rolePermission &&
                        rolePermission?.roleType?.rolePermission?.includes(
                          "WithdrawalList_view"
                        ) &&
                        rolePermission?.roleType?.rolePermission?.includes(
                          "ConversionList_view"
                        ) ? (
                        <div>Withdrawal and Conversion Request List</div>
                      ) : rolePermission &&
                        rolePermission?.roleType?.rolePermission?.includes(
                          "WithdrawalList_view"
                        ) ? (
                        <div>Withdrawal Request List</div>
                      ) : rolePermission &&
                        rolePermission?.roleType?.rolePermission?.includes(
                          "ConversionList_view"
                        ) ? (
                        <div>Conversion Request List</div>
                      ) : null}
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "FiatWalletManagement_view"
                )) ||
              (rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "CryptoWalletManagement_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/wallet-management"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/wallet-management" ||
                        location.pathname ===
                          "/dashboard/view-user-fiat-wallet" ||
                        location.pathname ===
                          "/dashboard/view-user-crypto-wallet"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      {/* <img
                        src={Index.Png.wallet}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      /> */}
                      <AccountBalanceWalletIcon className="admin-sidebar-icons" />
                      Wallet Management
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "FiatWalletManagement_view"
                )) ||
              (rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "CryptoWalletManagement_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/user-deposite-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/user-deposite-list"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      {/* <img
                        src={Index.Png.wallet}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      /> */}
                      <AccountBalanceWalletIcon className="admin-sidebar-icons" />
                      User Deposit List
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "FiatWalletManagement_view"
                )) ||
              (rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "CryptoWalletManagement_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/zero-wallet-management"
                      className={`admin-sidebar-link ${
                        location.pathname ===
                          "/dashboard/zero-wallet-management" ||
                        location.pathname ===
                          "/dashboard/view-zero-crypto-wallet" ||
                        location.pathname === "/dashboard/view-zero-fiat-wallet"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      {/* <img
                        src={Index.Png.wallet}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      /> */}
                      <AccountBalanceWalletIcon className="admin-sidebar-icons" />
                      Zero Wallet Management
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "PlatformFee_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/add-platform-fee"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/add-platform-fee"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index.Png.fee}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Platform Fee
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "UserMaster_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/user-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/user-list" ||
                        location.pathname === "/dashboard/add-user" ||
                        location.pathname === "/dashboard/edit-user" ||
                        location.pathname === "/dashboard/user-view"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index.Png.userMasterLogo}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      User Master
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "trendingList_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/trending-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/trending-list" ||
                        location.pathname === "/dashboard/trending-post-list" ||
                        location.pathname === "/dashboard/trending-reel-list" ||
                        location.pathname === "/dashboard/trending-hashtag-list"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index.Png.userMasterLogo}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Trending Lists
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "UserPost_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/user-post"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/user-post" ||
                        location.pathname === "/dashboard/user-post-view"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index.Png.userPostWhite}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      User Post
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "UserReport_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/user-report"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/user-report" ||
                        location.pathname === "/dashboard/view-post-report" ||
                        location.pathname ===
                          "/dashboard/view-comment-report" ||
                        location.pathname === "/dashboard/view-profile-report"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      {/* <img
                        src={Index.Svg.userlist}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      /> */}
                      <Index.ReportIcon className="admin-sidebar-icons" />
                      User Report
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "ContestList_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/contest-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/contest-list" ||
                        location.pathname === "/dashboard/add-contest" ||
                        location.pathname ===
                          "/dashboard/view-contest-details" ||
                        location.pathname === "/dashboard/reopen-contest" ||
                        location.pathname === "/dashboard/view-contest-analysis"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index.Png.contest}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Contests
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "ReelsList_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/user-reels"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/user-reels" ||
                        location.pathname === "/dashboard/user-reels-view"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index?.Png?.reelIcon}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      {/* User Reels List */}
                      User Cryp List
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "RoleMaster_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/role-master"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/role-master" ||
                        location.pathname === "/dashboard/role-master-add" ||
                        location.pathname === "/dashboard/role-master-view"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index?.Png?.roleListLogo}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Role Master
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "AdminMaster_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/admin-master-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/admin-master-list" ||
                        location.pathname === "/dashboard/admin-master-add" ||
                        location.pathname === "/dashboard/admin-master-view"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index?.Png?.adminMasterLogo}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Admin Master
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "UserFeedbackList_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/users-feedback-list"
                      className={`admin-sidebar-link ${
                        location.pathname ===
                          "/dashboard/users-feedback-list" ||
                        location.pathname ===
                          "/dashboard/single-user-feedback-list"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index?.Png?.feedback}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Users Feedback List
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "NotificationList_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/notifications-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/notifications-list" ||
                        location.pathname === "/dashboard/add-notification" ||
                        location.pathname === "/dashboard/edit-notification" ||
                        location.pathname === "/dashboard/view-notification"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      {/* <img
                        src={Index?.Png?.adminMasterLogo}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      /> */}
                      <NotificationsIcon className="admin-sidebar-icons" />
                      Notifications List
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "ContactUsList_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/contact-us-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/contact-us-list"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      {/* <img
                        src={Index.Png.contactUs}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      /> */}
                      <Index.ContactsIcon className="admin-sidebar-icons" />
                      Contact Us (Query) List
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "ruleManagement_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/rule-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/rule-list" ||
                        location.pathname === "/dashboard/rule-list"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index?.Png?.champion}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Rule Management
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "ContestWinnerList_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/contest-Participants-list"
                      className={`admin-sidebar-link ${
                        location.pathname ===
                          "/dashboard/contest-Participants-list" ||
                        location.pathname === "/dashboard/view-finished-contest"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index?.Png?.champion}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Contest Winner Management
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "StreakManagement_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin == true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/streak-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/streak-list" ||
                        location.pathname === "/dashboard/streak-list"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index?.Png?.streak}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Streak Management
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "AddDiamondPrice_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/add-diamond-price"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/add-diamond-price"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index?.Png?.addDiamond}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Add Diamond Price
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "DiamondTickUsersList_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/diamondtick-user-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/diamondtick-user-list"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      <img
                        src={Index?.Png?.diamondList}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Diamond Tick Users List
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "TransactionsList_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/stickers-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/stickers-list"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      {/* <img
                        src={Index?.Png?.adminMasterLogo}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      /> */}
                      <Index.ReceiptLongIcon className="admin-sidebar-icons" />
                      Stickers List
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}

              {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "TransactionsList_view"
                )) ||
              (rolePermission && rolePermission?.isAdmin === true) ? (
                <>
                  <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                    <Index.Link
                      to="/dashboard/transactions-list"
                      className={`admin-sidebar-link ${
                        location.pathname === "/dashboard/transactions-list"
                          ? "active"
                          : ""
                      }`}
                      onClick={handleClick}
                    >
                      {/* <img
                        src={Index?.Png?.adminMasterLogo}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      /> */}
                      <Index.ReceiptLongIcon className="admin-sidebar-icons" />
                      Transactions List
                    </Index.Link>
                  </Index.ListItem>
                </>
              ) : (
                ""
              )}
              {/* {(rolePermission &&
                rolePermission?.roleType?.rolePermission?.includes(
                  "AdminSupport_view"
                )) ||
                (rolePermission && rolePermission?.isAdmin === true) ? ( */}
              <>
                <Index.ListItem className="admin-sidebar-listitem cus-sidebar-list ">
                  <Index.Link
                    to="/dashboard/sub-admin-support"
                    className={`admin-sidebar-link ${
                      location.pathname === "/dashboard/admin-support"
                        ? "active"
                        : ""
                    }`}
                    onClick={handleClick}
                  >
                    <img
                      src={Index?.Png?.adminSupport}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    {/* <Index.ReceiptLongIcon className="admin-sidebar-icons" /> */}
                    Sub-Admin Support
                  </Index.Link>
                </Index.ListItem>
              </>
              {/* ) : (
                ""
              )} */}
              {rolePermission && rolePermission?.isAdmin === true ? (
                <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                  <Index.Link className="admin-sidebar-link">
                    <Index.Box
                      className="cus-cms-box"
                      onClick={handleClickAccount}
                    >
                      <img
                        src={Index.Svg.cms}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />{" "}
                      CMS
                      {open ? (
                        <Index.ExpandLess className="expandless-icon" />
                      ) : (
                        <Index.ExpandMore className="expandmore-icon" />
                      )}
                    </Index.Box>

                    <Index.Box className="submenu-main">
                      <Index.Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className="submenu-collapse"
                      >
                        <Index.List
                          component="div"
                          disablePadding
                          className="admin-sidebar-submenulist"
                        >
                          <Index.ListItem className="admin-sidebar-listitem ">
                            <Index.Link
                              to="/dashboard/terms-condition"
                              // className="admin-sidebar-link "
                              className={`admin-sidebar-link ${
                                location.pathname ===
                                "/dashboard/terms-condition"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              Terms & Conditions
                            </Index.Link>
                          </Index.ListItem>
                          <Index.ListItem className="admin-sidebar-listitem">
                            <Index.Link
                              to="/dashboard/privacy-policy"
                              // className="admin-sidebar-link "
                              className={`admin-sidebar-link ${
                                location.pathname ===
                                "/dashboard/privacy-policy"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              Privacy Policy
                            </Index.Link>
                          </Index.ListItem>
                          <Index.ListItem className="admin-sidebar-listitem">
                            <Index.Link
                              to="/dashboard/help-center"
                              // className="admin-sidebar-link "
                              className={`admin-sidebar-link ${
                                location.pathname === "/dashboard/help-center"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              Help Center
                            </Index.Link>
                          </Index.ListItem>
                          <Index.ListItem className="admin-sidebar-listitem">
                            <Index.Link
                              to="/dashboard/contact-us"
                              // className="admin-sidebar-link"
                              className={`admin-sidebar-link ${
                                location.pathname === "/dashboard/contact-us"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              Contact Us
                            </Index.Link>
                          </Index.ListItem>
                          <Index.ListItem className="admin-sidebar-listitem">
                            <Index.Link
                              to="/dashboard/about-us"
                              // className="admin-sidebar-link"
                              className={`admin-sidebar-link ${
                                location.pathname === "/dashboard/about-us"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              About Us
                            </Index.Link>
                          </Index.ListItem>
                        </Index.List>
                      </Index.Collapse>
                    </Index.Box>
                  </Index.Link>
                </Index.ListItem>
              ) : (
                ""
              )}
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
