import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { addEditMusic } from "../../../../redux/slices/adminService";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";

export default function AddEditMusic() {
  const navigate = PageIndex.useNavigate();
  const params = PageIndex.useLocation();
  const editData = params?.state?.data;
  const [loader, setLoader] = useState(false);
  const [musicUrl, setMusicUrl] = useState("");
  const [audioName, setAudioName] = useState("");

  const initialValues = {
    title: editData?.title || "", 
    artist: editData?.artist || "", 
    songUrl: editData?.songUrl || null, 
    size: editData?.size || "",
    duration: editData?.duration || "",
    imageUrl : editData?.imageUrl || null
  };

  // Formik

  const handleSubmit = async (values) => {
    setLoader(true);

    const formData = new FormData();
    formData.append("title", values?.title);
    formData.append("artist", values?.artist);
    formData.append("songUrl", values?.songUrl);
    formData.append("size", values?.size);
    formData.append("duration", values?.duration);
    formData.append("imageUrl", values?.imageUrl)
    if (editData?._id) {
      formData.append("songId", editData?._id);
    }
    addEditMusic(formData).then((data) => {
      if (data?.status === 200 || 201) {
        setLoader(false);
        navigate("/dashboard/music-list");
      } else {
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      }
    });
  };

  // Set musicUrl when editData?.songUrl is available
  useEffect(() => {
    if (editData?.songUrl) {
      setMusicUrl(`${imageUrl}${editData?.songUrl}`);
      // Extract the audio file name (assuming it's the last part of the URL)
      setAudioName(editData?.songUrl.split("/").pop());
    }
  }, [editData?.songUrl]);

  const handleImage = (file) => {
    let url = URL.createObjectURL(file);
    setMusicUrl(url);
    // Extract the name of the file
    setAudioName(file.name);
  };

  return (
    <>
      <Index.Box className="dashboard-content ">
        <PageIndex.BackButton
          onClick={() => navigate("/dashboard/music-list")}
        />
        <Index.Box className="barge-common-box">
          <Index.Typography
            className="admin-page-title cus-heading"
            component="h2"
            variant="h2"
          >
            {editData ? "Edit" : "Add"} Music
          </Index.Typography>
          <PageIndex.Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={PageIndex.addMusicSchema}
          >
            {({
              values,
              handleBlur,
              handleChange,
              errors,
              setFieldValue,
              touched,
            }) => (
              <PageIndex.Form>
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row-add-user"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                        <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 3",
                              md: "span 3",
                              lg: "span 3",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Music Image
                              </Index.FormHelperText>
                              <Index.Box className="profile-header cus-banner-box">
                                <Index.Box className="profile-input-box">
                                  <label htmlFor="btn-upload">
                                    <img
                                      src={
                                        values?.imageUrl
                                          ? values?.imageUrl instanceof Blob
                                            ? URL.createObjectURL(
                                                values?.imageUrl
                                              )
                                            : `${imageUrl}${values?.imageUrl}`
                                          : PageIndex.Png.musicAdd
                                      }
                                      alt="banner img"
                                      className={
                                        // values?.banner
                                        //   ?
                                        "banner-img"
                                        // :
                                        // "profile-img mb-15"
                                      }
                                    />

                                    <input
                                      id="btn-upload"
                                      name="imageUrl"
                                      disabled={loader}
                                      className="profile-upload-btn"
                                      type="file"
                                      accept="image/*"
                                      // hidden
                                      onChange={(e) => {
                                        setFieldValue(
                                          "imageUrl",
                                          e.target.files[0]
                                        );
                                      }}
                                    />
                                    <Index.Box className="profile-edit-main">
                                      <Index.Box
                                        className="edit-div"
                                        title="Edit"
                                      >
                                        {" "}
                                        <img
                                          className="profile-edit-img"
                                          src={PageIndex.Png.editLogo}
                                          alt=""
                                        />
                                      </Index.Box>
                                    </Index.Box>
                                  </label>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            {errors.imageUrl && touched.imageUrl && (
                              <p className="error-text">{errors.imageUrl}</p>
                            )}
                          </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Music Name{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Music Name"
                              name="title"
                              value={values.title}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  .replace(/\s\s+/g, " ");
                                setFieldValue("title", newValue);
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.title ? touched?.title : undefined
                              )}
                              helperText={
                                touched?.title ? errors?.title : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Duration{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control "
                              placeholder="Duration"
                              name="duration"
                              value={values.duration}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.duration ? touched?.duration : undefined
                              )}
                              helperText={
                                touched?.duration ? errors?.duration : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Artist <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Artist"
                              name="artist"
                              value={values.artist}
           
                              onChange={(e)=>{
                                const newValue = e.target.value
                                .replace(/^\s+/, "")
                                .replace(/\s\s+/g, " ");
                                setFieldValue("artist", newValue)
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.artist ? touched?.artist : undefined
                              )}
                              helperText={
                                touched?.artist ? errors?.artist : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Size <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              type="text"
                              className="form-control"
                              placeholder="Size"
                              name="size"
                              value={values.size}
                              onChange={(e)=>{
                                const newValue = e.target.value
                                .replace(/^\s+/, "")
                                .replace(/\s\s+/g, " ");
                                setFieldValue("size", newValue)
                              }}
                              
                              error={Boolean(
                                errors?.size ? touched?.size : undefined
                              )}
                              helperText={
                                touched?.size ? errors?.size : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Upload Music{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="select-position">
                            <input
                              type="file"
                              name="songUrl"
                              // accept="audio/*"
                              className="ori-input"
                              onChange={(event) => {
                                const files = event.target.files[0];
                                setFieldValue(
                                  "songUrl",

                                  files
                                );
                                handleImage(event.target.files[0]);
                              }}
                            />

                            <Index.Box className="select-file">
                              Upload Your Music
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Grid item xs={8}>
                          <Index.Box className="select-view-document-name">
                            <Index.Box className="document-name">
                              <Index.Typography
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  window.open(musicUrl);
                                }}
                              >
                                {audioName}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>

                        <Index.FormHelperText error>
                          {touched?.songUrl && errors?.songUrl}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="user-btn-flex">
                    <Index.Box className="save-btn-main border-btn-main">
                      <Index.Button
                        className="save-user-btn border-btn"
                        type="submit"
                        disabled={loader}
                      >
                        {loader ? (
                          <PageIndex.ButtonLoader
                            color="white"
                            size={14}
                            loading={loader}
                          />
                        ) : (
                          <>
                            <img
                              src={Index.Svg.save}
                              className="user-save-icon"
                              alt="Save Icon"
                            />
                            Save
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}
