import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { FieldArray, Formik } from "formik";
import { addEditRule } from "../../../../redux/slices/adminService";
import { rulesSchema } from "../../../../validation/Validation";
import * as Yup from "yup";

const AddEditRule = () => {
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [percentages, setPercentages] = useState([100]);
  const editData = location?.state?.row;
  const [errorState, setErrorState] = useState([]);

  const initialValues = {
    ruleName: editData?.ruleName ? editData?.ruleName : "",
    rules: editData?.rules
      ? editData?.rules
      : [
          {
            numberOfEnteries: "0 to 1",
            numberOfWinners: "",
            winningPrice: [
              {
                rank: "0 to 1",
                prize: "",
                remainingPrize: 100,
              },
            ],
          },
        ],
  };

  const handleSubmitRule = async (values) => {
    if (errorState?.filter((error) => error != null)?.length == 0) {
      setLoader(true);
      if (editData?._id) {
        values = {
          ...values,
          id: editData?._id,
        };
      }

      addEditRule(values).then((response) => {
        if (response?.status == 200) {
          navigate("/dashboard/rule-list");
          setLoader(false);
        } else {
          setTimeout(() => {
            setLoader(false);
          }, 6000);
        }
      });
    }
  };

  const calculation = (rules, ruleIndex, formik) => {
    const rule = rules[ruleIndex];
    const totalPrize = rule?.winningPrice?.reduce((total, { rank, prize }) => {
      if (prize && !isNaN(prize)) {
        const [start, end] = rank?.split(" to ").map(Number);
        const rankRange = end - (start === 0 ? 1 : start) + 1;
        return total + (isNaN(rankRange) ? 0 : rankRange) * prize;
      }
      return total;
    }, 0);

    const remainingPrize = 100 - totalPrize;

    setPercentages((prevPercentages) => {
      const newPercentages = [...prevPercentages];
      newPercentages[ruleIndex] = remainingPrize;
      return newPercentages;
    });
    setErrorState((prevErrors) => {
      const newErrors = [...prevErrors];

      if (remainingPrize !== 0) {
        newErrors[ruleIndex] = `Remaining prize must be zero`;
      } else {
        newErrors[ruleIndex] = null;
      }

      return newErrors;
    });
  };

  return (
    <>
      {!loading ? (
        <Index.Box className="dashboard-content add-user-containt">
          <PageIndex.BackButton
            onClick={() => navigate("/dashboard/rule-list")}
          />
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmitRule}
            validationSchema={rulesSchema}
            // validate={(values) => ({ context: values })}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <FieldArray name="rules">
                  {({ push, remove }) => (
                    <Index.Box className="barge-common-box">
                      <Index.Box className="flex-all user-list-inner-flex">
                        <Index.Typography
                          className="admin-page-title cus-heading"
                          component="h2"
                          variant="h2"
                        >
                          {editData ? "Edit" : "Add"} Rule
                        </Index.Typography>
                      </Index.Box>
                      {console.log(formik.values, 120)}
                      <Index.Box className="add-user-data-main">
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                          <Index.Box
                            display="grid"
                            className="display-row-add-user"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box add-user-input">
                                <Index.FormHelperText className="form-lable">
                                  Rule Name
                                  <span className="mandatory-asterisk">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder="Rule Name"
                                    name="ruleName"
                                    value={formik.values.ruleName}
                                    onChange={(e) => {
                                      const newValue = e.target.value
                                        .replace(/^\s+/, "")
                                        // .replace(/[^a-zA-Z]+/g, "")
                                        .replace(/\s\s+/g, " ");
                                      formik.setFieldValue(
                                        "ruleName",
                                        newValue
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(
                                      formik.errors?.ruleName
                                        ? formik.touched?.ruleName
                                        : undefined
                                    )}
                                    helperText={
                                      formik.touched?.ruleName
                                        ? formik.errors?.ruleName
                                        : undefined
                                    }
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        {formik.values.rules.map((rule, index) => (
                          <Index.Box sx={{ width: 1 }} className="grid-main">
                            <Index.Box
                              display="grid"
                              className="display-row-add-user"
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                            >
                              {index == 0 && (
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 12",
                                    md: "span 12",
                                    lg: "span 12",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="adduser-btn-main btn-main-primary rule-delete-btn-box">
                                    <>
                                      <Index.Button
                                        className="adduser-btn btn-primary"
                                        onClick={() => {
                                          const nextIndex =
                                            formik?.values?.rules?.[
                                              formik?.values?.rules?.length - 1
                                            ]?.numberOfEnteries;
                                          const [start, end] = nextIndex
                                            ?.split(" to ")
                                            ?.map(Number);
                                          const startNumber = end + 1;
                                          const endNumber =
                                            startNumber + (end - start);
                                          push({
                                            numberOfEnteries: `${startNumber} to ${endNumber}`,
                                            numberOfWinners: "",
                                            winningPrice: [
                                              {
                                                rank: "0 to 1",
                                                prize: "",
                                              },
                                            ],
                                          });
                                        }}
                                      >
                                        <img
                                          src={Index.Svg.plus}
                                          className="plus-icon remove-margin"
                                          alt="plus icon"
                                        />
                                      </Index.Button>
                                    </>
                                  </Index.Box>
                                </Index.Box>
                              )}

                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="input-box add-user-input">
                                  <Index.FormHelperText className="form-lable">
                                    Total Number of Entries{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Index.TextField
                                      fullWidth
                                      id="fullWidth"
                                      className="form-control email-input "
                                      placeholder="Number of Entries (0 to 1)"
                                      name={`rules[${index}].numberOfEnteries`}
                                      value={
                                        formik.values.rules[index]
                                          .numberOfEnteries
                                      }
                                      onChange={(e) => {
                                        const newValue = e.target.value
                                          .replace(/^\s+/, "")
                                          .replace(/\s\s+/g, " ");
                                        formik.setFieldValue(
                                          `rules[${index}].numberOfEnteries`,
                                          newValue
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Index.Box>
                                  <Index.FormHelperText error>
                                    {formik.touched.rules?.[index]
                                      ?.numberOfEnteries &&
                                      formik.errors.rules?.[index]
                                        ?.numberOfEnteries}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="input-box add-user-input">
                                  <Index.FormHelperText className="form-lable">
                                    Total Number of Winners{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Index.TextField
                                      fullWidth
                                      id="fullWidth"
                                      type="text"
                                      className="form-control"
                                      placeholder="Total Number of Winners"
                                      name={`rules[${index}].numberOfWinners`}
                                      value={
                                        formik.values.rules[index]
                                          .numberOfWinners
                                      }
                                      onChange={(e) => {
                                        const newValue = e.target.value
                                          .replace(/\D/g, "")
                                          .replace(/^\s+/, "")
                                          .replace(/\s\s+/g, " ");
                                        formik.setFieldValue(
                                          `rules[${index}].numberOfWinners`,
                                          newValue
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Index.Box>
                                  <Index.FormHelperText error>
                                    {formik.touched.rules?.[index]
                                      ?.numberOfWinners &&
                                      formik.errors.rules?.[index]
                                        ?.numberOfWinners}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <FieldArray name={`rules.${index}.winningPrice`}>
                              {({ push, remove }) => (
                                <>
                                  {formik.values.rules?.[
                                    index
                                  ]?.winningPrice.map((row, secondIndex) => (
                                    <Index.Box
                                      sx={{ width: 1 }}
                                      className="grid-main"
                                    >
                                      <Index.Box
                                        display="grid"
                                        className="display-row-add-user"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                      >
                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 6",
                                          }}
                                          className="grid-column"
                                        >
                                          <Index.Box className="input-box add-user-input custom-rank-form-group">
                                            <Index.FormHelperText className="form-lable">
                                              Rank{" "}
                                              <span className="mandatory-asterisk">
                                                *
                                              </span>
                                            </Index.FormHelperText>
                                            <Index.Box className="form-group ">
                                              <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                type="text"
                                                className="form-control "
                                                placeholder="Rank (0 to 1)"
                                                name={`rules[${index}]
                                                  .winningPrice[${secondIndex}]
                                                  .rank`}
                                                value={
                                                  formik.values.rules[index]
                                                    .winningPrice[secondIndex]
                                                    .rank
                                                }
                                                disabled={
                                                  secondIndex <
                                                  formik.values?.rules[index]
                                                    .winningPrice.length -
                                                    1
                                                }
                                                onChange={(e) => {
                                                  const newValue =
                                                    e.target.value
                                                      .replace(/^\s+/, "")
                                                      .replace(/\s\s+/g, " ");
                                                  formik.setFieldValue(
                                                    `rules.${index}.winningPrice[${secondIndex}].rank`,
                                                    newValue
                                                  );

                                                  const updatedRules = [
                                                    ...formik.values.rules,
                                                  ];

                                                  updatedRules[
                                                    index
                                                  ].winningPrice[
                                                    secondIndex
                                                  ].rank = newValue;

                                                  calculation(
                                                    updatedRules,
                                                    index,
                                                    formik
                                                  );
                                                }}
                                                onBlur={formik.handleBlur}
                                              />

                                              <Index.FormHelperText error>
                                                {formik.touched.rules?.[index]
                                                  ?.winningPrice?.[secondIndex]
                                                  ?.rank &&
                                                  formik.errors.rules?.[index]
                                                    ?.winningPrice?.[
                                                    secondIndex
                                                  ]?.rank}
                                              </Index.FormHelperText>
                                            </Index.Box>
                                            {typeof formik.errors?.rules?.[
                                              index
                                            ]?.winningPrice === "string" &&
                                            formik?.values?.rules?.[index]
                                              ?.winningPrice?.length -
                                              1 ===
                                              secondIndex ? (
                                              <Index.Box>
                                                <Index.FormHelperText error>
                                                  {formik.touched?.rules?.[
                                                    index
                                                  ]?.winningPrice &&
                                                    typeof formik.errors
                                                      ?.rules?.[index]
                                                      ?.winningPrice ===
                                                      "string" &&
                                                    formik.errors?.rules?.[
                                                      index
                                                    ]?.winningPrice}
                                                </Index.FormHelperText>
                                              </Index.Box>
                                            ) : (
                                              <></>
                                            )}
                                          </Index.Box>
                                        </Index.Box>
                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 5",
                                            md: "span 5",
                                            lg: "span 5",
                                          }}
                                          className="grid-column"
                                        >
                                          <Index.Box className="input-box add-user-input">
                                            <Index.Box className="form-group">
                                              {formik.values.rules?.[index]
                                                .winningPrice?.length -
                                                1 ==
                                                secondIndex && (
                                                <Index.FormHelperText className="remains-text-box">
                                                  {percentages[index] < 0 ||
                                                  percentages[index] > 100 ? (
                                                    <p className="remains-text">
                                                      {`Remaining ${0}%`}
                                                    </p>
                                                  ) : (
                                                    `Remaining ${
                                                      percentages[index] ==
                                                      undefined
                                                        ? 100.0
                                                        : percentages[
                                                            index
                                                          ]?.toFixed(2)
                                                    }%`
                                                  )}
                                                </Index.FormHelperText>
                                              )}
                                              <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                type="text"
                                                className="form-control"
                                                placeholder="Percentage of winning amount"
                                                name={`rules[${index}].winningPrice[${secondIndex}].prize`}
                                                disabled={
                                                  secondIndex <
                                                  formik.values?.rules[index]
                                                    .winningPrice.length -
                                                    1
                                                }
                                                value={
                                                  formik.values.rules[index]
                                                    .winningPrice[secondIndex]
                                                    .prize
                                                }
                                                onChange={(e) => {
                                                  const newValue =
                                                    e.target.value
                                                      .replace(/[^\d.]+/g, "")
                                                      .replace(
                                                        /^(\d*\.\d{0,4}).*$/,
                                                        "$1"
                                                      );
                                                  formik.setFieldValue(
                                                    `rules.${index}.winningPrice.${secondIndex}.prize`,
                                                    newValue
                                                  );

                                                  const updatedRules = [
                                                    ...formik.values.rules,
                                                  ];

                                                  updatedRules[
                                                    index
                                                  ].winningPrice[
                                                    secondIndex
                                                  ].prize = newValue;

                                                  calculation(
                                                    updatedRules,
                                                    index,
                                                    formik
                                                  );
                                                }}
                                                onBlur={formik.handleBlur}
                                              />

                                              <Index.Box className="rule-flex-box">
                                                <Index.FormHelperText error>
                                                  {formik.touched.rules?.[index]
                                                    ?.winningPrice?.[
                                                    secondIndex
                                                  ]?.prize ? (
                                                    percentages[index] < 0 ||
                                                    percentages[index] > 100 ? (
                                                      <p className="error-text">
                                                        Price must be less than
                                                        or equal to remaining %
                                                      </p>
                                                    ) : (
                                                      formik.errors.rules?.[
                                                        index
                                                      ]?.winningPrice?.[
                                                        secondIndex
                                                      ]?.prize
                                                    )
                                                  ) : null}
                                                </Index.FormHelperText>
                                                {errorState?.[index] !== "" &&
                                                formik?.values?.rules?.[index]
                                                  ?.winningPrice?.length -
                                                  1 ===
                                                  secondIndex &&
                                                formik.values?.rules?.[index]
                                                  ?.winningPrice?.[secondIndex]
                                                  ?.prize ? (
                                                  <Index.FormHelperText error>
                                                    {errorState?.[index]}
                                                  </Index.FormHelperText>
                                                ) : (
                                                  <></>
                                                )}
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>

                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 1",
                                            md: "span 1",
                                            lg: "span 1",
                                          }}
                                          className="grid-column"
                                        >
                                          {formik.values?.rules[index]
                                            .winningPrice.length -
                                            1 ==
                                            secondIndex && (
                                            <Index.Box className="add-remove-btn-box add-role-input">
                                              {formik.values?.rules[index]
                                                .winningPrice[secondIndex]
                                                .rank != "" &&
                                                formik.values?.rules[index]
                                                  .winningPrice[secondIndex]
                                                  .prize != "" &&
                                                percentages[index] > 0 &&
                                                +formik.values.rules[index]
                                                  .numberOfWinners -
                                                  1 !==
                                                  secondIndex && (
                                                  <Index.Tooltip title="Add text-box">
                                                    <Index.IconButton
                                                      color="primary"
                                                      aria-label="upload picture"
                                                      component="label"
                                                      onClick={() => {
                                                        const nextIndex =
                                                          formik?.values
                                                            ?.rules?.[index]
                                                            ?.winningPrice[
                                                            secondIndex
                                                          ]?.rank;
                                                        const [start, end] =
                                                          nextIndex
                                                            ?.split(" to ")
                                                            .map(Number);
                                                        const startNumber =
                                                          end + 1;
                                                        const endNumber =
                                                          startNumber +
                                                          (end - start);

                                                        push({
                                                          rank: `${startNumber} to ${endNumber}`,
                                                          prize: "",
                                                          remainingPrize:
                                                            formik?.values
                                                              ?.rules[index]
                                                              ?.remainingPrize,
                                                        });
                                                      }}
                                                    >
                                                      <Index.AddBoxIcon />
                                                    </Index.IconButton>
                                                  </Index.Tooltip>
                                                )}

                                              {formik.values.rules[index]
                                                .winningPrice?.length > 1 ? (
                                                <Index.Tooltip title="Remove text-box">
                                                  <Index.IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    onClick={() =>
                                                      remove(secondIndex)
                                                    }
                                                  >
                                                    <Index.IndeterminateCheckBoxIcon />
                                                  </Index.IconButton>
                                                </Index.Tooltip>
                                              ) : (
                                                <></>
                                              )}
                                            </Index.Box>
                                          )}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                            {formik.values.rules.length > 1 ? (
                              <>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6",
                                  }}
                                  className="grid-column"
                                ></Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="adduser-btn-main btn-main-primary rule-delete-btn-box">
                                    <>
                                      <Index.Button
                                        className="adduser-btn btn-primary"
                                        onClick={() => remove(index)}
                                      >
                                        <Index.DeleteForeverIcon className="delete-icon" />
                                      </Index.Button>
                                    </>
                                  </Index.Box>
                                </Index.Box>
                              </>
                            ) : (
                              <></>
                            )}
                          </Index.Box>
                        ))}

                        <Index.Box className="user-btn-flex">
                          <Index.Box className="save-btn-main border-btn-main">
                            <Index.Button
                              className="save-user-btn border-btn"
                              type="submit"
                              disabled={loader}
                            >
                              {loader ? (
                                <PageIndex.ButtonLoader
                                  color="white"
                                  size={14}
                                  loading={loader}
                                />
                              ) : (
                                <>
                                  <img
                                    src={Index.Svg.save}
                                    className="user-save-icon"
                                    alt="Save Icon"
                                  />
                                  {editData ? "Update" : "Save"}
                                </>
                              )}
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  )}
                </FieldArray>
              </form>
            )}
          </Formik>
        </Index.Box>
      ) : (
        <PageIndex.Loading />
      )}
    </>
  );
};

export default AddEditRule;
