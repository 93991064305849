import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { debounce } from "lodash";
import {
  archiveRestoreRule,
  getAllArchive,
} from "../../../../redux/slices/adminService";

const RuleArchiveList = () => {
  const navigate = PageIndex.useNavigate();
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const [archiveId, setArchiveId] = useState("");
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [ruleList, setRuleList] = useState([]);
  const [openArchive, setOpenArchive] = useState(false);

  const handleOpenArchive = (id) => {
    setOpenArchive(true);
    setArchiveId(id);
  };
  const handleCloseArchive = () => setOpenArchive(false);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const listOfArchive = () => {
    setLoader(true);
    getAllArchive({ currentPage, search, rowsPerPage }).then((res) => {
      if (res?.status === 200) {
        setLoader(false);
        setRuleList(res?.data);
        setCurrentPage(res?.currentPage);
        setPageCount(res?.totalCount ? res?.totalCount : 0);
      } else {
        setRuleList([]);
      }
    });
  };

  const handleRuleRestore = () => {
    setLoader(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", archiveId);
    archiveRestoreRule(urlencoded).then((data) => {
      if (data?.status == 200) {
        handleCloseArchive();
        listOfArchive();
      } else {
        handleCloseArchive();
        listOfArchive();
      }
    });
  };

  useEffect(() => {
    const debouncedFetch = debounce(listOfArchive, 300);
    if (search || currentPage || rowsPerPage) {
      debouncedFetch();
    } else {
      listOfArchive();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage, rowsPerPage]);
  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Archive Rule List
              </Index.Typography>
            </Index.Box>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      // name="search"
                      autoComplete="search"
                      value={search}
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ");
                        setCurrentPage(1);
                        setSearch(newValue);
                      }}
                    />
                    <span className="search-icon-box">
                      <img src={Index.Svg.search} className="search-icon" />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <PageIndex.BackButton
                onClick={() => navigate("/dashboard/rule-list")}
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-userlist"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="admin-sub-master-table-main action-column page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head cus-table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                S.No
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Rule Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Status
                              </Index.TableCell>

                              {(rolePermission &&
                                rolePermission?.roleType?.rolePermission?.includes(
                                  "ruleManagement_view"
                                )) ||
                              rolePermission?.roleType?.rolePermission?.includes(
                                "ruleManagement_edit"
                              ) ||
                              rolePermission?.roleType?.rolePermission?.includes(
                                "ruleManagement_delete"
                              ) ||
                              (rolePermission &&
                                rolePermission?.isAdmin === true) ? (
                                <>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    align="center"
                                  >
                                    Actions
                                  </Index.TableCell>
                                </>
                              ) : (
                                ""
                              )}
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {!loader ? (
                              ruleList?.length > 0 ? (
                                ruleList?.map((row, index) => (
                                  <Index.TableRow
                                    // key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.ruleName}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      <Index.Box className="table-view-toggle-btn">
                                        <Index.FormControlLabel
                                          control={
                                            <Index.IOSSwitch
                                              checked={row?.isActive === true}
                                              sx={{ m: 1 }}
                                              className="MuiSwitch-track-active archive-switch"
                                              onClick={(event) => {
                                                event.stopPropagation();
                                              }}
                                            />
                                          }
                                          className="switch-lable"
                                        />
                                      </Index.Box>
                                    </Index.TableCell>

                                    {(rolePermission &&
                                      rolePermission?.roleType?.rolePermission?.includes(
                                        "ruleManagement_view"
                                      )) ||
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "ruleManagement_edit"
                                    ) ||
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "ruleManagement_delete"
                                    ) ||
                                    (rolePermission &&
                                      rolePermission?.isAdmin === true) ? (
                                      <>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                          // align="center"
                                        >
                                          <Index.Box className="userdata-btn-flex admin-master-btn">
                                            {(rolePermission &&
                                              rolePermission?.roleType?.rolePermission?.includes(
                                                "ruleManagement_view"
                                              )) ||
                                            (rolePermission &&
                                              rolePermission?.isAdmin ===
                                                true) ? (
                                              <>
                                                <PageIndex.LightTooltip title="View">
                                                  <Index.IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    onClick={() => {
                                                      navigate(
                                                        "/dashboard/view-rule",
                                                        {
                                                          state: { row: row },
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <Index.RemoveRedEyeIcon />
                                                  </Index.IconButton>
                                                </PageIndex.LightTooltip>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {(rolePermission &&
                                              rolePermission?.roleType?.rolePermission?.includes(
                                                "ruleManagement_edit"
                                              )) ||
                                            (rolePermission &&
                                              rolePermission?.isAdmin ===
                                                true) ? (
                                              <>
                                                <PageIndex.LightTooltip title="Restore">
                                                  <Index.IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    onClick={() => {
                                                      handleOpenArchive(
                                                        row?._id
                                                      );
                                                    }}
                                                  >
                                                    <Index.RestoreIcon />
                                                  </Index.IconButton>
                                                </PageIndex.LightTooltip>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </Index.Box>
                                        </Index.TableCell>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Index.TableRow>
                                ))
                              ) : (
                                <PageIndex.RecordNotFound colSpan={4} />
                              )
                            ) : (
                              <PageIndex.TableLoader colSpan={4} />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* Pagination */}

          <Index.Box className="pagination-main">
            <Index.TablePagination
              rowsPerPageOptions={[
                { label: "All", value: -1 },
                10,
                25,
                50,
                75,
                100,
              ]}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={currentPage - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
              variant="outlined"
              shape="rounded"
              className="pagination"
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.ArchiveModel
        openArchive={openArchive}
        handleCloseArchive={handleCloseArchive}
        handleArchiveRecord={handleRuleRestore}
        isArchive={false}
      />
    </>
  );
};

export default RuleArchiveList;
