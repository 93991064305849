import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService, {
  doGet,
  doGetByQueary,
  doGetLogout,
  doGetParams,
  doPost,
  doPostData,
  doPostFormData,
  doPostGetParams,
} from "../../config/dataService";
import { Api } from "../../config/Api";
import { toast } from "react-toastify";

export const loginAdmin = createAsyncThunk("admin/loginAdmin", async (data) => {
  try {
    const response = await doPost(Api.Auth.login, data);
    if (response?.data?.status === 200) {
      DataService.defaults.headers.common.auth = response?.data?.data?.token;
      return response.data;
    }
  } catch (error) {}
});

export const AdminForgotPassword = async (data) => {
  try {
    const response = await doPost(Api.Auth.forgotPassword, data);
    if (response?.data?.status === 200 || 201) {
      return response?.data;
    }
  } catch (error) {}
};

export const verifyOtp = async (data) => {
  try {
    const response = await doPost(Api.Auth.otpVerify, data);
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const resetPassword = async (data) => {
  try {
    const response = await doPost(Api.Auth.resetPassword, data);
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const updateProfile = createAsyncThunk(
  "admin/updateProfile",
  async (data) => {
    try {
      const response = await doPostFormData(Api.Auth.updateProfile, data);
      if (response?.data?.status === 200) {
        return response?.data;
      }
    } catch (error) {}
  }
);

export const changePasswordProfile = async (data) => {
  try {
    const response = await doPost(Api.Auth.changePassword, data);
    if (response?.data?.status === 200) {
      return response?.data?.status;
    }
  } catch (error) {}
};

// Start User Master
export const getAllUserMasterList = async (data, controller) => {
  try {
    const response = await doGetByQueary(
      Api.User.getAllUser,
      {
        params: data,
      },
      { signal: controller?.signal }
    );
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const addUserMaster = async (data) => {
  try {
    const response = await doPost(Api.Auth.addUser, data);
    if (response?.data?.status === 201) {
      return response?.data;
    }
  } catch (error) {}
};

export const editUserMaster = async (data, id) => {
  try {
    const id = data.get("id");
    const response = await doPostGetParams(Api.User.editUser, data, id);
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const deleteUserMaster = async (id) => {
  try {
    const response = await doPost(Api.User.deleteUser, id);
    if (response?.data?.status === 200) {
      return response?.data?.status;
    }
  } catch (error) {}
};

// End User Master

//admin master
export const getAllFollowerListOfUser = async (data) => {
  try {
    const response = await doGetByQueary(Api.Pages.Get_all_user_follower_list, {
      params: {
        search: data?.search,
        page: data?.currentPage,
        limit: data?.rowsPerPage,
        id: data?.userId,
      },
    });
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getAllFollowingListOfUser = async (data) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.Get_all_user_following_list,
      {
        params: {
          search: data?.search,
          page: data?.currentPage,
          limit: data?.rowsPerPage,
          id: data?.userId,
        },
      }
    );
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getAllSubAdmin = async (data) => {
  try {
    const response = await doGetByQueary(Api.Pages.Get_all_sub_admin, {
      params: {
        search: data?.search,
        page: data?.currentPage,
        limit: data?.rowsPerPage,
      },
    });
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const deleteSubAdmin = async (id) => {
  try {
    const response = await doPost(Api.Pages.Sub_admin_delete, id);
    if (response?.data?.status === 200) {
      return response?.data?.status;
    }
  } catch (error) {}
};
export const deleteContactUsAdmin = async (id) => {
  try {
    const response = await doPost(Api.Pages.Contact_us_delete, id);
    if (response?.data?.status === 200) {
      return response?.data?.status;
    }
  } catch (error) {}
};
export const deleteNotificationByAdmin = async (id) => {
  try {
    const response = await doPost(Api.Pages.Notification_by_admin_delete, id);
    if (response?.data?.status === 200) {
      return response?.data?.status;
    }
  } catch (error) {}
};

export const addSubAdmin = async (data) => {
  try {
    const response = await doPost(Api.Pages.Add_sub_admin, data);
    if (response?.data?.status === 200) {
      // toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {}
};

export const editSubAdmin = async (data) => {
  try {
    const response = await doPost(Api.Pages.Edit_sub_admin, data);
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {}
};

// Terms And Condition
export const getHelpCenter = async () => {
  try {
    const response = await doGet(Api.CMS.Get_Help_center);
    if (response?.data?.status === 200) {
      return response.data;
    }
  } catch (error) {}
};

export const getAdminSupportAction = async () => {
  try {
    const response = await doGet(Api.CMS.getAdminSupport);
    if (response?.data?.status === 200) {
      return response.data;
    }
  } catch (error) {}
};
export const updateHelpCenter = async (data) => {
  try {
    const response = await doPost(Api.CMS.Add_edit_Help_center, data);
    if (response?.data?.status === 200) {
      return response.data;
    }
  } catch (error) {}
};

export const getCmsDetails = async () => {
  try {
    const response = await doGet(Api.CMS.get_CMS_Details);
    if (response?.data?.status === 200) {
      return response.data;
    }
  } catch (error) {}
};
export const getTermsCondtion = async () => {
  try {
    const response = await doGet(Api.Pages.terms_condition_user);
    if (response?.data?.status === 200) {
      return response.data;
    }
  } catch (error) {}
};
export const getPrivacyPolicy = async () => {
  try {
    const response = await doGet(Api.Pages.privacy_policy_user);
    if (response?.data?.status === 200) {
      return response.data;
    }
  } catch (error) {}
};

export const createTermsConditionCms = async (data) => {
  try {
    const response = await doPost(Api.CMS.create_Terms_Condition, data);
    if (response?.data?.status === 200) {
      return response.data;
    }
  } catch (error) {}
};

export const createPrivacyPolicyCms = async (data) => {
  try {
    const response = await doPost(Api.CMS.create_Privacy_Policy, data);
    if (response?.data?.status === 200) {
      return response.data;
    }
  } catch (error) {}
};

export const createContactUs = async (data) => {
  try {
    const response = await doPost(Api.CMS.createContactUs, data);

    if (response?.data?.status === 200 || 201) {
      // toast.success(response?.data?.message || "Admin create");
      return response.data;
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const adminSupportAction = async (data) => {
  try {
    const response = await doPost(Api.CMS.adminSupport, data);

    if (response?.data?.status === 200 || 201) {
      // toast.success(response?.data?.message || "Admin create");
      return response.data;
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const getAllPostListByAdmin = async (data, controller) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.Get_allposts_listbyadmin,
      {
        params: {
          page: data?.page,
          search: data?.search,
          limit: data?.rowsPerPage,
          startDate: data?.startDate ? data?.startDate : "",
          endDate: data?.endDate ? data?.endDate : "",
          today: data?.today,
        },
      },
      { signal: controller?.signal }
    );
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const getSinglePostByAdmin = async (id) => {
  try {
    const response = await doGetParams(
      Api.Pages.Get_singlepost_listbyadmin,
      id
    );
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const getAllReelsByAdmin = async (data) => {
  try {
    const response = await doGetByQueary(Api.Pages.Get_allreels_listbyadmin, {
      params: {
        page: data?.page,
        search: data?.search,
        limit: data?.rowsPerPage,
        startDate: data?.startDate ? data?.startDate : "",
        endDate: data?.endDate ? data?.endDate : "",
        today: data?.today,
      },
    });
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const getSingleReelByAdmin = async (id) => {
  try {
    const response = await doGetParams(Api.Pages.Get_singlereel_byadmin, id);
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
// get all role master
export const getAllRoleMaster = async (data) => {
  try {
    const response = await doGetByQueary(Api.Pages.Get_roleMasterList, {
      params: {
        search: data?.search,
        page: data?.currentPage,
        limit: data?.rowsPerPage,
      },
    });
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

// add and edit role master
export const addRoleMaster = async (data) => {
  try {
    const response = await doPostData(Api.Pages.RoleMaster_add, data);
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const editRoleMaster = async (data) => {
  try {
    const response = await doPostData(Api.Pages.RoleMaster_edit, data);
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

// role delete
export const roleMasterDelete = async (data) => {
  try {
    const response = await doPostData(Api.Pages.RoleMaster_delete, data);
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

//get single user by admin
export const getSingleUserByAdmin = async (data) => {
  try {
    const response = await DataService.get(
      `${Api.Pages.Get_single_user_by_admin}?id=${data}`
    );

    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

//contact us list

export const getAllContactUsList = async (page, search, rowsPerPage) => {
  try {
    const response = await doGetByQueary(Api.Pages.Contact_Us_List, {
      params: {
        page: page,
        search: search,
        limit: rowsPerPage,
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const editAboutUs = async (data) => {
  try {
    const response = await DataService.post(Api.CMS.Edit_About_Us, data);
    toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

//contact us list

export const getTodayReportAction = async () => {
  try {
    const response = await doGet(Api.Pages.get_today_report);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const getOverViewReportAction = async () => {
  try {
    const response = await doGet(Api.Pages.get_overView_report);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

// get all users feedback
export const getAllUsersFeedback = async (page, search, rowsPerPage) => {
  try {
    const response = await doGetByQueary(Api.Pages.Get_users_feedback_list, {
      params: {
        page: page,
        search: search,
        limit: rowsPerPage,
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
// get single users feedback
export const getSingleUserFeedback = async (data) => {
  try {
    // const response = await doPost(Api.Pages.Get_user_feedback, data);
    const response = await doPost(Api.Pages.Get_user_feedback, data, false);
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
// add notification
export const addEditNotifiction = async (data) => {
  try {
    const response = await doPost(Api.Pages.Add_Edit_Notification, data);
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

// get all noifications
export const getAllNotifications = async (page, search, rowsPerPage) => {
  try {
    const response = await doGetByQueary(Api.Pages.Get_All_Notification, {
      params: {
        page: page,
        search: search,
        limit: rowsPerPage,
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
// send notification
export const sendNotifications = async (data) => {
  try {
    const response = await doPost(Api.Pages.Send_Notification, data);
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const getAllCommentReportsList = async (page, search, today) => {
  try {
    const params = {
      comment: "comment",
      search: search,
      page: page,
      today: today,
    };

    const response = await doGetByQueary(Api.Pages.Get_All_Reports_Admin, {
      params,
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getAllPostReportsList = async (page, search, today) => {
  try {
    const params = {
      post: "post",
      search: search,
      page: page,
      today: today,
    };

    const response = await doGetByQueary(Api.Pages.Get_All_Reports_Admin, {
      params,
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getAllReelReportsList = async (page, search, today) => {
  try {
    const params = {
      reelId: "reel",
      search: search,
      page: page,
      today: today,
    };

    const response = await doGetByQueary(Api.Pages.Get_All_Reports_Admin, {
      params,
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getAllProfileReportsList = async (page, search, today) => {
  try {
    const params = {
      profile: "profile",
      search: search,
      page: page,
      today: today,
    };

    const response = await doGetByQueary(Api.Pages.Get_All_Reports_Admin, {
      params,
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getSinglePostReportsList = async (postId) => {
  try {
    const response = await doGetByQueary(Api.Pages.Get_Single_Reports_Admin, {
      params: {
        postId: postId,
      },
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getSingleReelReportsList = async (reelId) => {
  try {
    const response = await doGetByQueary(Api.Pages.Get_Single_Reports_Admin, {
      params: {
        reelId: reelId,
      },
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getSinglecommentReportsList = async (commentId) => {
  try {
    const response = await doGetByQueary(Api.Pages.Get_Single_Reports_Admin, {
      params: {
        commentId: commentId,
      },
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getSingleProfileReportsList = async (profileId) => {
  try {
    const response = await doGetByQueary(Api.Pages.Get_Single_Reports_Admin, {
      params: {
        profileId: profileId,
      },
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const deleteProfileReport = async (id) => {
  try {
    const response = await doPost(Api.Pages.Delete_Profile_report, {
      id: id?.id,
      type: "Profile_Report_Delete",
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const deletePostReport = async (id) => {
  try {
    const response = await doPost(Api.Pages.Delete_Post_report, {
      id: id?.id,
      type: "Post_Report_Delete",
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const deleteReelReport = async (id) => {
  try {
    const response = await doPost(Api.Pages.Delete_reel_report, {
      id: id?.id,
      type: "Reel_Report_Delete",
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const deleteReel = async (id) => {
  try {
    const response = await doPost(Api.Pages.delete_reel, id);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const deleteCommentReport = async (id) => {
  try {
    const response = await doPost(Api.Pages.Delete_Comment_report, id);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const sendMailPostReports = async (postId) => {
  try {
    const response = await doPost(Api.Pages.Send_Post_Email, {
      postId: postId,
      type: "Post_Report_Warning",
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const sendMailReelReports = async (reelId) => {
  try {
    const response = await doPost(Api.Pages.Send_Post_Email, {
      reelId: reelId,
      type: "Reel_Report_Warning",
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getEmailContent = async (type) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.get_email_content,
      {
        params: { type: type },
      },
      null,
      false
    );
    if (response?.data?.status == 200) {
      // toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {}
};

export const addEditEmailContent = async (data) => {
  try {
    const response = await doPost(
      Api.Pages.add_edit_email_content,
      data,
      false
    );
    if (response?.data?.status == 200 || response?.data?.status == 201) {
      // toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {}
};

export const sendMailCommentReports = async (commentId) => {
  try {
    const response = await doPost(Api.Pages.Send_Comment_Email, {
      commentId: commentId,
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const sendMailProfileReports = async (profileId) => {
  try {
    const response = await doPost(Api.Pages.Send_Profile_Email, {
      profileId: profileId,
      type: "Profile_Report_Warning",
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const suspendUser = async (userId) => {
  try {
    const response = await doPost(Api.Pages.suspend_user_byadmin, {
      userId: userId,
      type: "Profile_Report_Suspend",
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getContenstparticipantList = async (page) => {
  try {
    const response = await doGetByQueary(Api.Pages.contest_Participant_list, {
      params: {
        page: page,
      },
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const searchContenstparticipant = async (obj) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.search_contest_Participant,
      {
        params: {
          search: obj?.search,
          startDate: obj?.startDate ? obj?.startDate : "",
          endDate: obj?.endDate ? obj?.endDate : "",
          page: obj?.page,
          limit: obj?.rowsPerPage,
          today: obj?.today,
        },
      },
      null,
      false
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const searchSubContenstparticipant = async (obj) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.search_sub_contest,
      {
        params: {
          search: obj?.search,
          startDate: obj?.startDate ? obj?.startDate : "",
          endDate: obj?.endDate ? obj?.endDate : "",
          page: obj?.page,
          today: obj?.today,
        },
      },
      null,
      false
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const addEditContestData = async (data) => {
  try {
    const response = await doPost(Api.Pages.Add_Edit_Contest, data);
    if (response?.data?.status == 201) {
      // toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {}
};
// get contest list
export const getAllContest = async (page, today, rowsPerPage) => {
  try {
    const response = await doGetByQueary(Api.Pages.Get_All_Contest, {
      params: {
        page: page,
        limit: rowsPerPage,
        today: today ? today : "",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const getAllSubContest = async (page, contestId) => {
  try {
    const response = await doGetByQueary(Api.Pages.Get_Sub_Contest, {
      params: {
        contestId: contestId,
        page: page,
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const addDiamondPrice = async (data) => {
  try {
    const response = await doPost(Api.Pages.add_diamond_price, data);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getDiamondPrice = async () => {
  try {
    const response = await doGet(Api.Pages.get_diamond_price);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getPurchasedDiamondByUserList = async (data) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.get_purchased_diamond_by_user,
      {
        params: {
          search: data?.search,
          page: data?.currentPage,
          limit: data?.rowsPerPage,
          startDate: data?.startDate ? data?.startDate : "",
          endDate: data?.endDate ? data?.endDate : "",
        },
      }
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const provideDiamondTickByUser = async (data) => {
  try {
    const response = await doPost(Api.Pages.provide_diamond_tick_byadmin, data);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const deleteContestAction = async (data) => {
  try {
    const response = await doPost(Api.Pages.delete_contest, data);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const cancelContestAction = async (data) => {
  try {
    const response = await doPost(Api.Pages.cancel_contest, data);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getCompleteContest = async (
  selectedFromDate,
  selectedToDate,
  page,
  search
) => {
  try {
    const response = await doGetByQueary(Api.Pages.complete_contest, {
      params: {
        page: page,
        search: search,
        startDate: selectedFromDate,
        endDate: selectedToDate,
      },
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const contestAnalysisAction = async (data) => {
  try {
    const response = await doPost(Api.Pages.contest_analysis, data, false);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getUserActivity = async (data) => {
  try {
    const response = await doPost(Api.User.userActivity, data, false);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const suffleContestParticipantAction = async (data) => {
  try {
    const response = await doGet(
      `${Api.Pages.suffle_contest_participant}?id=${data}`
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getSingleContest = async (data) => {
  try {
    const response = await doGet(`${Api.Pages.get_single_contest}?id=${data}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const randomWinnerDeclare = async (data) => {
  try {
    const response = await doPost(Api.Pages.Popularity_winner_declare, data);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const winnerListWithCheckBox = async (data) => {
  const contestWinnerList = {
    id: data,
  };

  try {
    const response = await DataService.post(
      Api.Pages.Popularity_winner_List_declare,
      contestWinnerList,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

// DataService.post(url, data, {
//   headers: {
//     "Content-Type": "application/x-www-form-urlencoded",
//   },
// });
// if (response?.data?.status === 201 || 200) {
//   if(isShow){
//     PageIndex.toast.success(response?.data?.message,{ autoClose: 2000 });
//   }

//   return response;
// }
// } catch (error) {
// PageIndex.toast.error(error?.response?.data?.message,{ autoClose: 2000 });
// }

export const WinnerDeclare = async (data) => {
  const contestWinner = {
    id: data,
  };

  try {
    const response = await doPost(
      Api.Pages.Announce_winner_popularity,
      contestWinner
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const WinnerRemove = async (data) => {
  const contestRemoveWinner = {
    id: data,
  };

  try {
    const response = await doPost(
      Api.Pages.Remove_winner_popularity,
      contestRemoveWinner
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getUserFollowersList = async (id) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.get_user_activity_for_graph,
      {
        params: {
          userId: id?.userId,
          startDate: id?.startDate,
          endDate: id?.endDate,
          days: id?.days,
        },
      }
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getUserContestActivityLog = async (id) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.get_user_contest_activity_log,
      {
        params: {
          userId: id?.userId,
          contestId: id?.contestId,
        },
      }
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getAllContestForGraph = async (id) => {
  try {
    const response = await doGetByQueary(Api.Pages.get_all_contest_for_graph, {
      params: {
        userId: id?.userId,
      },
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getSingleSubAdmin = async (id) => {
  try {
    const response = await doGetByQueary(Api.Pages.admin_get_sub_admin, {
      params: {
        id: id,
      },
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const addRandomWinnerList = async (data) => {
  try {
    const response = await doPost(Api.Pages.random_add_winner, data, true);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getPopularContestParticipant = async (data) => {
  try {
    const response = await doGet(
      `${Api.Pages.get_popular_contest_participant}?id=${data}`
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const resendOTP = async (data) => {
  try {
    const response = await doPost(Api.Auth.resendOtp, data, true);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

// Crypro and platform
export const addUpdatePlatformKey = async (data) => {
  try {
    const response = await doPost(
      Api.Pages.add_update_platform_fee,
      data,
      true
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getPlatformFee = async () => {
  try {
    const response = await doGet(`${Api.Pages.get_platform_fee}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const addUpdateCryptoName = async (data) => {
  try {
    const response = await doPost(Api.Pages.add_edit_crypto_name, data, true);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const DepositeMoneyIntoWallet = async (data) => {
  try {
    const response = await doPost(
      Api.Pages.deposite_money_into_wallet,
      data,
      true
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const deleteCryptoName = async (data) => {
  try {
    const response = await doPost(Api.Pages.delete_crypto_name, data, true);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const activeDeactiveCrypto = async (data) => {
  try {
    const response = await doPost(Api.Pages.active_deactive_crypto, data, true);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getCryptoList = async (data) => {
  try {
    const response = await doGetByQueary(Api.Pages.get_crypto_name_list, {
      params: {
        search: data?.search,
        page: data?.currentPage,
        limit: data?.rowsPerPage,
      },
    });
    // const response = await doGet(`${Api.Pages.get_crypto_name_list}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getLiveCryptoList = async () => {
  try {
    const response = await doGet(`${Api.Pages.get_live_cryptoList}`);

    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

//
export const getWithdrawalRequestList = async (data, controller) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.withdraw_crypto_request_list,
      {
        params: {
          type: data?.type,
          today: data?.today,
          search: data?.search,
          limit: data?.rowsPerPage,
          page: data?.page,
          ...(data?.withdrawRequest
            ? { withdrawRequest: true }
            : { cryptoRequest: true, fiatRequest: true }),
        },
      },
      { signal: controller?.signal }
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const updateWithdrawRequest = async (data) => {
  try {
    const response = await doPost(
      Api.Pages.update_withdraw_request,
      data,
      true
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const updateCryptoRequest = async (data) => {
  try {
    const response = await doPost(Api.Pages.update_crypto_request, data, true);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const updateFiatRequest = async (data) => {
  try {
    const response = await doPost(Api.Pages.update_fiat_request, data, true);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getFiatWallet = async (data, controller) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.get_fiat_wallet_data_for_admin,
      {
        params: {
          search: data?.search,
          page: data?.currentPage,
          limit: data?.rowsPerPage,
        },
      },
      { signal: controller.signal }
    );
    // const response = await doGet(`${Api.Pages.get_fiat_wallet_data_for_admin}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getZeroBalanceFiatWallet = async (data, controller) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.get_fiat_zero_balance_wallet_data_for_admin,
      {
        params: {
          search: data?.search,
          page: data?.currentPage,
          limit: data?.rowsPerPage,
          type: data?.Currencytype,
          today: data?.type,
        },
      },
      { signal: controller.signal }
    );
    // const response = await doGet(`${Api.Pages.get_fiat_wallet_data_for_admin}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const updateFiatWalletStatus = async (data) => {
  try {
    const response = await doPost(
      Api.Pages.active_deactive_user_wallet,
      data,
      true
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const updateCryptoWalletStatus = async (data) => {
  try {
    const response = await doPost(
      Api.Pages.active_deactive_user_crypto_wallet,
      data,
      true
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getUserWalletDataForAdmin = async (id) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.get_user_wallet_data_for_admin,
      {
        params: {
          userId: id,
        },
      }
    );
    // const response = await doGet(`${Api.Pages.get_fiat_wallet_data_for_admin}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getUserCryptoWalletList = async (data, controller) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.crytpo_wallet_data_for_admin,
      {
        params: {
          search: data?.search,
          page: data?.currentPage,
          limit: data?.rowsPerPage,
        },
      },
      { signal: controller.signal }
    );
    // const response = await doGet(`${Api.Pages.get_fiat_wallet_data_for_admin}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getSingleUserCryptoWalletDataForAdmin = async (id) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.single_crytpo_wallet_data_for_admin,
      {
        params: {
          userId: id,
        },
      }
    );
    // const response = await doGet(`${Api.Pages.get_fiat_wallet_data_for_admin}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getAssestsList = async (data) => {
  try {
    const response = await doGetByQueary(Api.Pages.get_assests_list_for_admin, {
      params: {
        userId: data,
      },
    });
    // const response = await doGet(`${Api.Pages.get_fiat_wallet_data_for_admin}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getFiatQR = async (data) => {
  try {
    const response = await doGetByQueary(Api.Pages.get_qr_code, {
      params: {
        userId: data,
      },
    });
    // const response = await doGet(`${Api.Pages.get_fiat_wallet_data_for_admin}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getCryptoQR = async (data) => {
  try {
    const response = await doGetByQueary(Api.Pages.generate_qr_for_crypto, {
      params: {
        userId: data,
      },
    });
    // const response = await doGet(`${Api.Pages.get_fiat_wallet_data_for_admin}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getUserTransactionList = async (data) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.get_user_transaction_list_for_admin,
      {
        params: {
          userId: data?.userId,
          search: data?.search,
          limit: data?.limit,
          page: data?.page,
          status: data?.status,
          startDate: data?.obj?.startDate,
          endDate: data?.obj?.endDate,
          currencyType: data?.type == "fiat" ? "fiat" : "crypto",
        },
      }
    );
    // const response = await doGet(`${Api.Pages.get_fiat_wallet_data_for_admin}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const sendAmountAction = async (data) => {
  try {
    const response = await doPost(
      Api.Pages.transfer_amount_to_admin,
      data,
      true
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getAllUserTransactionList = async (data) => {
  try {
    const params = {
      userId: data?.userId,
      search: data?.search,
      page: data?.page,
      startDate: data?.obj?.startDate,
      endDate: data?.obj?.endDate,
      today: data?.today,
      type: data?.type,
      currencyType: data?.currencyType,
      limit: data?.rowsPerPage,
      currency: data?.currency,
      cryptoCoinType: data?.cryptoCoinType,
      // currencyType: data?.type == "fiat" ? "fiat" : "crypto",
    };

    const response = await doGetByQueary(
      Api.Pages.get_alluser_transaction_list_for_admin,
      {
        params,
      }
    );
    // const response = await doGet(`${Api.Pages.get_fiat_wallet_data_for_admin}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const addEditStreakNotification = async (data) => {
  try {
    const response = await doPost(
      Api.Pages.add_edit_streak_notification,
      data,
      true
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getStreakNotification = async (data) => {
  try {
    const response = await doGet(`${Api.Pages.get_streak_notification}`);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getStreakListAction = async (page, search, rowsPerPage) => {
  try {
    const response = await doGetByQueary(Api.Pages.get_streak_list, {
      params: {
        page: page,
        search: search,
        limit: rowsPerPage,
      },
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const singleUserDetails = async (data) => {
  try {
    const response = await doPost(
      Api.Pages.get_single_user_streak,
      data,
      false
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const streakHistoryAction = async (data) => {
  try {
    const response = await doPost(Api.Pages.get_streak_history, data, false);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getCurrencyListAction = async (data) => {
  try {
    const response = await doGetByQueary(Api.Pages.get_currency_list, {
      params: {
        currency: data,
      },
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getCommonListOfCryptoToken = async (data) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.get_common_crypto_currency_list,
      {
        params: {
          currency: data,
        },
      }
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getCommonListOfFiatToken = async (data) => {
  try {
    const response = await doGetByQueary(
      Api.Pages.get_common_fiat_currency_list,
      {
        params: {
          currency: data,
        },
      }
    );
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

// stcikers
export const addEditStickers = async (data) => {
  try {
    const response = await doPost(Api.Pages.add_edit_stickers, data);
    if (response?.data?.status === 201) {
      return response?.data;
    }
  } catch (error) {}
};

export const deleteSticker = async (data) => {
  try {
    const response = await doPostData(Api.Pages.delete_stickers, data);
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const getAllStickersList = async (data) => {
  try {
    const response = await doGetByQueary(Api.Pages.get_all_stickers, {
      params: {
        search: data?.search,
        page: data?.currentPage,
        limit: data?.rowsPerPage,
      },
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const autoLogout = async (data) => {
  try {
    const response = await doGet(Api.Pages.auto_logout);
    return response;
  } catch (error) {}
};

export const contestDisable = async (data) => {
  try {
    const response = await doPost(Api.Pages.contest_disable, data);
    if (response?.data?.status == 200) {
      // toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {}
};

export const getContestWinnerList = async (
  selectedFromDate,
  selectedToDate,
  page,
  search,
  rowsPerPage
) => {
  try {
    const response = await doGetByQueary(Api.Pages.Contest_winnerList, {
      params: {
        page: page,
        search: search,
        startDate: selectedFromDate,
        endDate: selectedToDate,
        limit: rowsPerPage,
      },
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getJoinedContest = async (data) => {
  try {
    const response = await doPost(Api.Pages.user_joied_contest, data, false);
    if (response?.data?.status == 200) {
      // toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {}
};

export const removeDiamondTick = async (data) => {
  try {
    const response = await doPost(Api.Pages.remove_diamond_tick, data);
    if (response?.data?.status == 200) {
      // toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {}
};

// trending
export const getTrendingPostAction = async (
  page,
  search,
  rowsPerPage,
  today
) => {
  try {
    const params = {
      search: search,
      page: page,
      limit: rowsPerPage,
    };
    const response = await doGetByQueary(Api.Pages.get_trending_post, {
      params,
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getTrendingHashTagAction = async (
  page,
  search,
  rowsPerPage,
  today
) => {
  try {
    const params = {
      search: search,
      page: page,
      limit: rowsPerPage,
    };
    const response = await doGetByQueary(Api.Pages.get_trending_hashtag, {
      params,
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};
export const getTrendingReelAction = async (
  page,
  search,
  rowsPerPage,
  today
) => {
  try {
    const params = {
      search: search,
      page: page,
      limit: rowsPerPage,
    };
    const response = await doGetByQueary(Api.Pages.get_trending_reel, {
      params,
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getLikeCommentGraphAction = async (id) => {
  try {
    const response = await doGetByQueary(Api.Pages.get_graph_data, {
      params: {
        postId: id?.postId,
        startDate: id?.startDate,
        endDate: id?.endDate,
        days: id?.days,
      },
    });
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {}
};

export const getDeviceHistoryAction = async (userId) => {
  try {
    const response = await doGetByQueary(Api.User.get_device_history, {
      params: { userId: userId },
    });
    if (response?.data?.status == 200) {
      // toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {}
};

export const getStreakChat = async (data) => {
  try {
    const response = await doPost(Api.Pages.get_streak_chat, data, false);
    if (response?.data?.status == 200) {
      // toast.success(response?.data?.message);
      return response?.data;
    }
  } catch (error) {}
};

export const addEditRule = async (data) => {
  try {
    const response = await doPostData(Api.Pages.add_edit_rules, data);
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const copyRule = async (data) => {
  try {
    const response = await doPostData(Api.Pages.copy_rule, data);
    return response?.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const activeDeactiveRule = async (data) => {
  try {
    const response = await doPost(Api.Pages.active_deactive_rule, data, true);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const archiveRestoreRule = async (data) => {
  try {
    const response = await doPost(Api.Pages.archive_restore_rule, data, true);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const deleteRule = async (data) => {
  try {
    const response = await doPost(Api.Pages.delete_rule, data, true);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const getAllRules = async (data) => {
  try {
    const response = await doGetByQueary(Api.Pages.get_all_rules, {
      params: {
        search: data?.search,
        page: data?.currentPage,
        limit: data?.rowsPerPage,
      },
    });
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const getAllArchive = async (data) => {
  try {
    const response = await doGetByQueary(Api.Pages.get_all_archive_rules, {
      params: {
        search: data?.search,
        page: data?.currentPage,
        limit: data?.rowsPerPage,
      },
    });
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const getRulesForSelect = async () => {
  try {
    const response = await doGet(Api.Pages.get_all_rules_for_select);
    if (response?.data?.status === 200) {
      return response.data;
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const addEditMusic = async (data) => {
  try {
    const response = await doPost(Api.Pages.add_edit_music, data);
    if (response?.data?.status === 201) {
      return response?.data;
    }
  } catch (error) {}
};
export const deleteMusic = async (data) => {
  try {
    const response = await doGetParams(Api.Pages.delete_music, data);
    if (response?.data?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const getAllMusic = async (data) => {
  try {
    const response = await doGetByQueary(Api.Pages.get_all_music, {
      params: {
        search: data?.search,
        page: data?.currentPage,
        limit: data?.rowsPerPage,
      },
    });
    if (response?.data?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};