import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getCryptoList,
  deleteCryptoName,
  getAllMusic,
  deleteMusic,
} from "../../../../redux/slices/adminService";
import PageIndex from "../../../PageIndex";

import { debounce } from "lodash";

export default function MusicList() {
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [loader, setLoader] = useState(true);
  const [deleteId, setDeleteId] = useState();
  const [cryptoList, setCryptoList] = useState([]);
  const [liveCryptoList, setLiveCryptoList] = useState([]);
  const [singleCrypto, setSingleCrypto] = useState("");
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const [isView, setIsView] = useState(false);
  const [open, setOpen] = useState(false);
  const [excel, setExcel] = useState([]);
  const navigate = PageIndex.useNavigate();

  const handleOpen = (row) => {
    navigate("/dashboard/add-edit-music", { state: { data: row } });
  };
  // add user modal
  //Delete Modal
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const deleteCryptoNam = (id) => {
    setIsDeleteButtonDisabled(true);
    deleteMusic(deleteId).then((data) => {
      handleCloseDelete();
      setIsDeleteButtonDisabled(false);
      setLoader(true);
      fetchMusicList();
    });
  };

  const fetchMusicList = () => {
    setLoader(true);
    getAllMusic({ currentPage, search, rowsPerPage }).then((data) => {
      if (data?.status == 200) {
        setCryptoList(data?.data);
        if (data?.currentPage) {
          setCurrentPage(data?.currentPage);
        }
        setPageCount(data?.totalCount ? data?.totalCount : 0);
        setLoader(false);
        setIsDeleteButtonDisabled(false);
      } else {
        setCryptoList([]);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    });
  };
  useEffect(() => {
    const debouncedFetch = debounce(fetchMusicList, 300);
    if (search || currentPage || rowsPerPage) {
      debouncedFetch();
    } else {
      fetchMusicList();
    }
    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage, rowsPerPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Music List
              </Index.Typography>
            </Index.Box>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth_123"
                      type="text"
                      value={search}
                      autoComplete="search"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ");
                        setSearch(newValue);
                        setCurrentPage(1);
                        // getCryptoList(newValue);
                      }}
                    />
                    <span className="search-icon-box">
                      <img src={Index.Svg.search} className="search-icon" />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="flex-all user-list-inner-flex">
                <Index.Box className="adduser-btn-main btn-main-primary">
                  {(rolePermission &&
                    rolePermission?.roleType?.rolePermission?.includes(
                      "CryptoList_add"
                    )) ||
                  (rolePermission && rolePermission?.isAdmin === true) ? (
                    <>
                      <Index.Button
                        className="adduser-btn btn-primary"
                        onClick={()=>  navigate("/dashboard/add-edit-music")}
                      >
                        <img
                          src={Index.Svg.plus}
                          className="plus-icon"
                          alt="plus icon"
                        />
                        Add Music
                      </Index.Button>
                    </>
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-userlist"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="cryptolist-table-main action-column page-table-main">
                      {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}

                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                        // sx={{ maxHeight:400 }}
                      >
                        <Index.Table
                          stickyHeader
                          aria-label="sticky table"
                          sx={{ minWidth: 650 }}
                          className="table"
                        >
                          <Index.TableHead className="table-head cus-table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                S.No
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Music Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Duration
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Size
                              </Index.TableCell>

                              {rolePermission?.roleType?.rolePermission?.includes(
                                "CryptoList_view"
                              ) ||
                              rolePermission?.roleType?.rolePermission?.includes(
                                "CryptoList_edit"
                              ) ||
                              rolePermission?.roleType?.rolePermission?.includes(
                                "CryptoList_delete"
                              ) ||
                              (rolePermission &&
                                rolePermission?.isAdmin === true) ? (
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Actions
                                </Index.TableCell>
                              ) : (
                                ""
                              )}
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {!loader ? (
                              cryptoList?.length > 0 ? (
                                cryptoList?.map((row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.title ? row?.title : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.duration ? row?.duration : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.size ? row?.size : "-"}
                                    </Index.TableCell>

                                    {rolePermission?.roleType?.rolePermission?.includes(
                                      "CryptoList_view"
                                    ) ||
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "CryptoList_edit"
                                    ) ||
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "CryptoList_delete"
                                    ) ||
                                    (rolePermission &&
                                      rolePermission?.isAdmin === true) ? (
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        <Index.Box className="userdata-btn-flex">
                                          {(rolePermission &&
                                            rolePermission?.roleType?.rolePermission?.includes(
                                              "CryptoList_edit"
                                            )) ||
                                          (rolePermission &&
                                            rolePermission?.isAdmin ===
                                              true) ? (
                                            <>
                                              <PageIndex.LightTooltip title="Edit">
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() => {
                                                    handleOpen(row);
                                                  }}
                                                >
                                                  <Index.EditIcon />
                                                </Index.IconButton>
                                              </PageIndex.LightTooltip>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {(rolePermission &&
                                            rolePermission?.roleType?.rolePermission?.includes(
                                              "CryptoList_delete"
                                            )) ||
                                          (rolePermission &&
                                            rolePermission?.isAdmin ===
                                              true) ? (
                                            <>
                                              <PageIndex.LightTooltip title="Delete">
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() => {
                                                    handleOpenDelete(row._id);
                                                  }}
                                                >
                                                  <Index.DeleteIcon />
                                                </Index.IconButton>
                                              </PageIndex.LightTooltip>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Index.Box>
                                      </Index.TableCell>
                                    ) : (
                                      ""
                                    )}
                                  </Index.TableRow>
                                ))
                              ) : (
                                <PageIndex.RecordNotFound colSpan={7} />
                              )
                            ) : (
                              <PageIndex.TableLoader colSpan={7} />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                      {/* </Paper> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {cryptoList.length ? (
            <Index.Box className="pagination-main">
              <Index.TablePagination
                rowsPerPageOptions={[
                  { label: "All", value: -1 },
                  10,
                  25,
                  50,
                  75,
                  100,
                ]}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={currentPage - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
            </Index.Box>
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>

      {/* Delete Modal */}
      <PageIndex.DeleteModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteRecord={deleteCryptoNam}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
    </>
  );
}
