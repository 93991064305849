import usericon from "./images/png/user.png";
import userlist1 from "./images/png/userlist1.png";
import bergurmenu from "./images/png/bergur-menu.png";
import graph1 from "./images/png/graph1.png";
import dashboadgraph from "./images/png/dashboard-graph.png";
import pagenotfound from "./images/png/pagenotfound.png";
import socialMediaIcon from "./images/png/social_media_icon.png";
import socialMediaLogo from "./images/png/social-media-logo.png";
import socialMediaLogoWhite from "./images/png/social-media-logo-white.png";
import reelIcon from "./images/png/reel.png";
import roleListLogo from "./images/png/RoleListLogo.png";
import adminMasterLogo from "./images/png/AdminMasterLogo.png";
import userMasterLogo from "./images/png/userMasterLogo.png";
import contactUs from "./images/png/contact-us.png";
import usericonDasboard from "./images/png/user-dashboard-icon.png";
import userDefaultIcon from "./images/png/userDefaultIcon.png";
import moneyTransfer from "./images/png/transfer-money.png";
import report from "./images/png/report.png";
import trophy from "./images/png/trophy.png";
import registerUser from "./images/png/register-user.png";
import withdraw from "./images/png/withdraw.png";
import reward from "./images/png/reward.png";
import blockUser from "./images/png/block-user.png";
import userMailWarning from "./images/png/email.png";
import reopen from "./images/png/reopen.png";
import editLogo from "./images/png/edit.png";
import cameraImage from "./images/png/Cameraimages.png";
import winnerAnnounce from "./images/png/winnerAnnounce.png";
import transaction from "./images/png/transaction.png";
import wallet from "./images/png/wallet.png";
import QRScanner from "./images/png/qr-code.png";
import upArrow from "./images/png/upload.png";
import downArrow from "./images/png/down-arrow.png";
import bitcoin from "./images/png/bitcoin.png";
import currencyExchange from "./images/png/currency-exchange.png";
import settings from "./images/png/settings.png";
import backarrow from "./images/png/back-arrow.png";
import close from "./images/png/close.png";
import fiatMoney from "./images/png/fiat-money.png";
import crypto from "./images/png/crypto.png";
import userPost from "./images/png/userPost.png";
import reels from "./images/png/reels.png";
import reportedUser from "./images/png/reported user.png";
import diamond from "./images/png/diamond.png";
import accountBalance from "./images/png/accountBalance.png";
import transactionHistory from "./images/png/transaction-history.png";
import totalUsers from "./images/png/group.png";
import fee from "./images/png/fee.png";
import userPostWhite from "./images/png/userPostWhite.png";
import champion from "./images/png/champion.png";
import contest from "./images/png/contest.png";
import feedback from "./images/png/feedback.png";
import streak from "./images/png/fire.png";
import diamondList from "./images/png/diamondList.png";
import addDiamond from "./images/png/addDiamond.png";
import unBlockUser from "./images/png/unblock_user.png";
import showChat from "./images/png/showChat.png";
import dataAnalysis from "./images/png/data-analysis.png";
import adminSupport from "./images/png/adminSupport.png";
// import exportExcel  from './images/png/Exportexcel.png'
import exportExcel from "./images/png/Exportexcelpng.png";
import sendIcon from "./images/png/sendIcon.png";
import logo from "./images/png/logo.png";
import homeIcon from "./images/png/home.png";
import aboutIcon from "./images/png/about.png";
import featureIcon from "./images/png/features.png";
import infoIcon from "./images/png/info.png";
import appleIcon from "./images/png/apple.png";
import androidIcon from "./images/png/android.png";
import homePurpleIcon from "./images/png/purple-home.png";
import aboutPurpleIcon from "./images/png/purple-about.png";
import featurePurpleIcon from "./images/png/purple-feature.png";
import infoPurpleIcon from "./images/png/purple-info.png";
import heroSectionImage from "./images/png/Vector (2).png";
import musicAdd from "./images/png/music_Add.png"

const Png = {
  musicAdd,
  heroSectionImage,
  homePurpleIcon,
  aboutPurpleIcon,
  featurePurpleIcon,
  infoPurpleIcon,
  appleIcon,
  androidIcon,
  homeIcon,
  aboutIcon,
  featureIcon,
  infoIcon,
  logo,
  sendIcon,
  exportExcel,
  dataAnalysis,
  cameraImage,
  editLogo,
  userMailWarning,
  pagenotfound,
  usericon,
  userlist1,
  bergurmenu,
  graph1,
  dashboadgraph,
  socialMediaIcon,
  socialMediaLogo,
  socialMediaLogoWhite,
  usericonDasboard,
  reelIcon,
  roleListLogo,
  adminMasterLogo,
  userMasterLogo,
  contactUs,
  userDefaultIcon,
  moneyTransfer,
  report,
  trophy,
  registerUser,
  withdraw,
  reward,
  blockUser,
  reopen,
  winnerAnnounce,
  transaction,
  wallet,
  QRScanner,
  upArrow,
  downArrow,

  bitcoin,
  currencyExchange,
  settings,
  backarrow,
  close,
  fiatMoney,
  crypto,
  userPost,
  reels,
  reportedUser,
  diamond,
  accountBalance,
  transactionHistory,
  totalUsers,
  fee,
  userPostWhite,
  champion,
  contest,
  feedback,
  streak,
  diamondList,
  addDiamond,
  unBlockUser,
  showChat,
  adminSupport,
};

export default Png;
